/* Common, default styles for the notification box */

.s-alert-box,
.s-alert-box * {
    box-sizing: border-box;
}

.s-alert-box {
    position: fixed;
    background: rgba(42,45,50,0.85);
    padding: 22px;
    line-height: 1.4;
    z-index: 1000;
    pointer-events: none;
    color: rgba(250,251,255,0.95);
    font-size: 100%;
    font-family: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
    max-width: 300px;
    transition: top .4s, bottom .4s;
}

.s-alert-box.s-alert-show {
    pointer-events: auto;
}

.s-alert-box a {
    color: inherit;
    opacity: 0.7;
    font-weight: 700;
}

.s-alert-box a:hover,
.s-alert-box a:focus {
    opacity: 1;
}

.s-alert-box p {
    margin: 0;
}

.s-alert-box.s-alert-show,
.s-alert-box.s-alert-visible {
    pointer-events: auto;
}

.s-alert-close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 4px;
    top: 4px;
    overflow: hidden;
    text-indent: 100%;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.s-alert-close:hover,
.s-alert-close:focus {
    outline: none;
}

.s-alert-close::before,
.s-alert-close::after {
    content: '';
    position: absolute;
    width: 3px;
    height: 60%;
    top: 50%;
    left: 50%;
    background: #fff;
}

.s-alert-close:hover::before,
.s-alert-close:hover::after {
    background: #fff;
}

.s-alert-close::before {
    -webkit-transform: translate(-50%,-50%) rotate(45deg);
    transform: translate(-50%,-50%) rotate(45deg);
}

.s-alert-close::after {
    -webkit-transform: translate(-50%,-50%) rotate(-45deg);
    transform: translate(-50%,-50%) rotate(-45deg);
}

/* positions */

.s-alert-bottom-left {
    top: auto;
    right: auto;
    bottom: 30px;
    left: 30px;
}
.s-alert-top-left {
    top: 30px;
    right: auto;
    bottom: auto;
    left: 30px;
}
.s-alert-top-right {
    top: 30px;
    right: 30px;
    bottom: auto;
    left: auto;
}
.s-alert-bottom-right { /*default*/
    top: auto;
    right: 30px;
    bottom: 30px;
    left: auto;
}
.s-alert-bottom {
    width: 100%;
    max-width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
}
.s-alert-top {
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
}

/* conditions */

.s-alert-info {
    background: #00A2D3;
    color: #fff;
}
.s-alert-success {
    background: #27AE60;
    color: #fff;
}
.s-alert-warning {
    background: #F1C40F;
    color: #fff;
}
.s-alert-error {
    background: #E74C3C;
    color: #fff;
}

[class^="s-alert-effect-"].s-alert-hide,
[class*=" s-alert-effect-"].s-alert-hide {
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

/* height measurement helper */
.s-alert-box-height {
    visibility: hidden;
    position: fixed;
}

.VirtualSelectGrid {
  z-index: 1;
}

.VirtualizedSelectOption {
  display: flex;
  align-items: center;
  padding: 0 .5rem;
  cursor: pointer;
}
.VirtualizedSelectFocusedOption {
  background-color: rgba(0, 126, 255, 0.1);
}
.VirtualizedSelectDisabledOption {
  opacity: 0.5;
}
.VirtualizedSelectSelectedOption {
  font-weight: bold;
}

.rc-tag-input {
  width: auto;
  height: auto;
  min-height: 35px;
  display: inline-block;
  background: #fff;
  border: solid 1px #eee;
}
.rc-tag-input li {
  float: left;
  display: inline-block;
}
.rc-tag-input .tag {
  position: relative;
  float: left;
  display: inline-block;
  padding: 4px 6px 4px 8px;
  background: #eaeaea;
  margin: 4px;
  border-radius: 3px;
}
.rc-tag-input .tag .name {
  text-decoration: none;
  color: black;
  float: left;
  font-size: 1em;
  padding: 2px;
}
.rc-tag-input .tag .btn-close {
  position: relative;
  display: inline-block;
  float: left;
  width: 0.8em;
  height: 0.8em;
  margin-top: 0.28em;
  margin-left: 10px;
}
.rc-tag-input .tag .btn-close::before {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 50%;
  content: '';
  min-width: 1px;
  width: 0.08em;
  height: 100%;
  background-color: black;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}
.rc-tag-input .tag .btn-close::after {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 50%;
  content: '';
  min-width: 1px;
  width: 0.08em;
  height: 100%;
  background-color: black;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
.rc-tag-input .input-area {
  display: inline-block;
  float: left;
  padding: 7px 4px;
}
.rc-tag-input .input-area input {
  height: 100%;
  border: none;
  font-size: 1em;
}
.rc-tag-input .input-area input:focus {
  outline: none;
}

.rc-input-wrap {
  display: inline-block;
  position: relative;
}
.rc-input-wrap .rc-autocomplete {
    top: 100%;
    left: 0;
    position: absolute;
    width: 100%;
}
.rc-input-wrap .rc-autocomplete  .rc-label-autocomplete {
  display: inline-block;
  float: left;
  padding: 10px;
  line-height: inherit;
}
.rc-input-wrap .rc-autocomplete.list {
  display: inline-block;
  width: auto;
}
.rc-input-wrap .rc-autocomplete.list li {
  width: calc(100% - 6px);
}
.rc-input-wrap .rc-autocomplete.list li a {
  margin: 4px;
}

